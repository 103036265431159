// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgSettings = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.981 1.25a.75.75 0 0 0-.725.56l-.538 2.043-1.869.788L6.5 3.44a.75.75 0 0 0-1.03.03l-2 2a.75.75 0 0 0-.051 1.003L4.59 7.917l-.767 1.887-1.994.466a.75.75 0 0 0-.58.73v2a.75.75 0 0 0 .552.723l2.05.56.789 1.871-1.2 1.347a.75.75 0 0 0 .029 1.03l2 2a.75.75 0 0 0 1.004.05l1.445-1.177 1.843.758.51 2.022a.75.75 0 0 0 .728.566h2a.75.75 0 0 0 .727-.566l.512-2.022 1.898-.786c.194.16.432.364.664.566.188.164.363.318.49.431l.153.136.055.05a.75.75 0 0 0 1.031-.029l2-2a.75.75 0 0 0 .042-1.015l-1.188-1.402.78-1.883 2.025-.527a.75.75 0 0 0 .56-.726L22.75 11a.75.75 0 0 0-.565-.727l-2.013-.512-.78-1.88 1.18-1.397a.75.75 0 0 0-.042-1.014l-2-2a.75.75 0 0 0-1.017-.04l-1.387 1.185-1.938-.797-.527-2.009a.75.75 0 0 0-.726-.559zm.093 3.342.485-1.842h.798l.475 1.81a.75.75 0 0 0 .44.503l2.707 1.114a.75.75 0 0 0 .773-.124l1.208-1.032 1.022 1.022-1.03 1.218a.75.75 0 0 0-.12.77l1.097 2.651a.75.75 0 0 0 .509.44l1.812.461v.814l-1.825.475a.75.75 0 0 0-.504.44l-1.098 2.65a.75.75 0 0 0 .12.772l1.038 1.224-1.012 1.013-.183-.16c-.373-.325-.838-.722-1.095-.906a.75.75 0 0 0-.723-.082l-2.65 1.097a.75.75 0 0 0-.44.509l-.462 1.821h-.832l-.46-1.821a.75.75 0 0 0-.442-.51l-2.605-1.072a.75.75 0 0 0-.76.112L6.051 18.99l-1.02-1.02 1.045-1.172a.75.75 0 0 0 .13-.79l-1.114-2.645a.75.75 0 0 0-.493-.432l-1.849-.505v-.832l1.793-.418a.75.75 0 0 0 .524-.448L6.148 8.07a.75.75 0 0 0-.113-.755L5.01 6.052 6.03 5.031l1.176 1.045a.75.75 0 0 0 .79.131l2.644-1.115a.75.75 0 0 0 .434-.5M9.75 12a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0M12 8.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5"
      clipRule="evenodd"
    />
  </svg>
);
export const SettingsIcon = forwardRef(SvgSettings);
